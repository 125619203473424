import Phone from "pages/Phone";
import "./App.css";
import { SIPProvider } from "data/context/Sip";
import { Routes, Route, HashRouter } from "react-router-dom";
import KeyBoardPhone from "pages/KeyBoardPhone";
import InCalling from "pages/InCalling";

function App() {
  return (
    <div className="App">
      <SIPProvider
        options={{
          domain: "vtp0004067.cfvn.cloud",
          webSocketServer: "wss://rtc.cloudfone.vn:4433",
          userName: "101",
          password: "PzSmSs94nc@",
        }}
      >
        <HashRouter>
          <Routes>
            <Route path="/calling/:phone" element={<InCalling />} />
            <Route path="keyboard-phone" element={<KeyBoardPhone />} />
            <Route path="*" element={<Phone />} />
          </Routes>
        </HashRouter>
      </SIPProvider>
    </div>
  );
}

export default App;
