import { IMAGES_APP } from "assets";
import { styles } from "./styles";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";

const KeyBoardPhone = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const handleAddNumber = useCallback((number: string) => {
    setPhoneNumber((prev) => prev + number);
  }, []);

  const handleRemoveNumber = useCallback(() => {
    setPhoneNumber((prev) => prev.slice(0, -1));
  }, []);

  return (
    <div style={styles.modal}>
      <div style={styles.modalHeader}>
        <Link to={"/"}>
          <img
            src={IMAGES_APP.arrowLeft}
            alt="back"
            style={styles.iconLeftBack}
          />
          <button style={styles.modalBackButton}>Back</button>
        </Link>
      </div>
      <div style={styles.modalBody}>
        {/* Hiển thị số */}
        <div style={styles.phoneDisplay}>
          <span />
          <span style={styles.phoneNumber}>{phoneNumber}</span>
          <img
            onClick={handleRemoveNumber}
            src={IMAGES_APP.deleteText}
            alt="back"
            style={styles.iconDeleteText}
          />
        </div>

        {/* Bàn phím số */}
        <div style={styles.keypad}>
          {["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"].map(
            (key) => (
              <button
                key={key}
                style={styles.key}
                onClick={() => handleAddNumber(key)}
              >
                {key}
              </button>
            )
          )}
        </div>

        {/* Nút gọi */}
        <div>
          <Link to={`/calling/${phoneNumber}`}>
            <img
              src={IMAGES_APP.btnCall}
              alt="call"
              style={styles.iconBtnCall}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default KeyBoardPhone;
