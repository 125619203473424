import { useSIPProvider } from "data/context/Sip";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HistoryPhone } from "types/Phone";

const usePhone = () => {
  const [search, setSearch] = useState<string>("");
  const [historyPhone, setHistoryPhone] = useState<HistoryPhone[]>([]);
  const [activeTab, setActiveTab] = useState<"recent" | "contact">("recent");
  const [modalShowKeyboard, setModalShowKeyboard] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [modalShowCalling, setModalShowCalling] = useState<boolean>(false);
  const { sessionManager } = useSIPProvider();

  const handleSetShowModalCallOutGoing = useCallback((value: boolean) => {
    setPhoneNumber("");
    setModalShowCalling(value);
  }, []);

  const handleSetShowKeyBoard = useCallback(
    (value: boolean, clearPhoneNumber?: boolean) => {
      if (clearPhoneNumber) {
        setPhoneNumber("");
      }
      setModalShowKeyboard(value);
    },
    []
  );

  const handleClickCall = useCallback(async () => {
    await sessionManager?.call(`sip:${phoneNumber}@vtp0004067.cfvn.cloud`, {});
    handleSetShowKeyBoard(false);
    handleSetShowModalCallOutGoing(true);
  }, [
    handleSetShowKeyBoard,
    handleSetShowModalCallOutGoing,
    phoneNumber,
    sessionManager,
  ]);

  const handleSetActiveTab = useCallback((value: "recent" | "contact") => {
    setActiveTab(value);
  }, []);

  const handleSetHistory = useCallback((value: HistoryPhone[]) => {
    setHistoryPhone(value);
  }, []);

  useEffect(() => {
    const getDataHistoryPhoneFromStorage = () => {
      const data = localStorage.getItem("historyPhone");
      if (data) {
        handleSetHistory(JSON.parse(data));
      }
    };

    //Call the function
    getDataHistoryPhoneFromStorage();
  }, []);

  const handleSetPhone = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    []
  );

  const handleAddNumber = useCallback((number: string) => {
    setPhoneNumber((prev) => prev + number);
  }, []);

  const handleRemoveNumber = useCallback(() => {
    setPhoneNumber((prev) => prev.slice(0, -1));
  }, []);

  return useMemo(
    () => ({
      activeTab,
      search,
      historyPhone,
      modalShowKeyboard,
      phoneNumber,
      modalShowCalling,
      handleClickCall,
      handleAddNumber,
      handleRemoveNumber,
      handleSetShowKeyBoard,
      handleSetPhone,
      handleSetActiveTab,
      handleSetShowModalCallOutGoing,
    }),
    [
      activeTab,
      search,
      historyPhone,
      modalShowKeyboard,
      phoneNumber,
      modalShowCalling,
      handleClickCall,
      handleAddNumber,
      handleRemoveNumber,
      handleSetShowKeyBoard,
      handleSetPhone,
      handleSetActiveTab,
      handleSetShowModalCallOutGoing,
    ]
  );
};

export default usePhone;
