import { IMAGES_APP } from "assets";
import { useSessionCall } from "data/context/Sip";
import { styles } from "./styles";
import { SessionState } from "sip.js";
import { CallTimer } from "../CallTimer";

interface CallActionUIProps {
  sessionId: string;
  handleSetShowModalCallOutGoing: (value: boolean) => void;
}

const CallActionUI = ({
  sessionId,
  handleSetShowModalCallOutGoing,
}: CallActionUIProps) => {
  const {
    isHeld,
    isMuted,
    decline,
    hangup,
    hold,
    mute,
    answer,
    session,
    unhold,
    unmute,
    direction,
    timer,
  } = useSessionCall(sessionId);
  return (
    <div>
      {session.state === SessionState.Establishing && <p>Calling...</p>}
      <p>
        {timer?.answeredAt && (
          <CallTimer
            isEnd={session.state === SessionState.Terminated}
            startAt={timer.answeredAt}
          />
        )}
      </p>
      {![SessionState.Terminating, SessionState.Terminated].includes(
        session.state
      ) && (
        <img
          onClick={() => {
            hangup();
            handleSetShowModalCallOutGoing(false);
          }}
          src={IMAGES_APP.cancelBtn}
          alt="cancel"
          style={styles.iconCancelBtn}
        />
      )}
    </div>
  );
};

export default CallActionUI;
