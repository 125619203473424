import { useParams } from "react-router-dom";
import { styles } from "./styles";
import { IMAGES_APP } from "assets";
import { useEffect, useState } from "react";
import { useSessionCall, useSIPProvider } from "data/context/Sip";
import CallActionUI from "./CallActionUI";

const InCalling = () => {
  const { sessionManager, sessions } = useSIPProvider();
  const { phone } = useParams<{ phone: string }>();
  const [sessionId, setSessionId] = useState<string | null>(null);

  // Lấy ID của session đầu tiên
  useEffect(() => {
    const sessionKeys = Object.keys(sessions);
    if (sessionKeys.length > 0) {
      setSessionId(sessionKeys[0]);
    }
  }, [sessions]);

  useEffect(() => {
    const callToPhone = async () => {
      if (sessionManager && phone) {
        await sessionManager.call(`sip:${phone}@vtp0004067.cfvn.cloud`, {});
      }
    };

    if (!sessionId) {
      callToPhone();
    }
  }, [sessionManager, phone, sessionId]);

  return (
    <div style={styles.modal}>
      <div>
        <img src={IMAGES_APP.avatar} alt="avatar" style={styles.avatar} />
      </div>
      <p>{phone}</p>
      <div style={styles.wrapperActionInCall}>
        <CallActionUI sessionId={Object.keys(sessions)[0]} />
      </div>
      <img
        src={IMAGES_APP.cancelBtn}
        alt="cancel"
        style={styles.iconCancelBtn}
      />
    </div>
  );
};

export default InCalling;
