import search from "./images/svg/search.svg";
import time from "./images/svg/time.svg";
import people from "./images/svg/people.svg";
import keyboardDialog from "./images/svg/keyboardDialog.svg";
import arrowLeft from "./images/svg/arrowLeft.svg";
import deleteText from "./images/svg/deleteText.svg";
import btnCall from "./images/svg/btnCall.svg";
import avatar from "./images/svg/avatar.svg";
import cancelBtn from "./images/svg/cancelBtn.svg";

export const IMAGES_APP = {
  search,
  time,
  people,
  keyboardDialog,
  arrowLeft,
  deleteText,
  btnCall,
  avatar,
  cancelBtn,
};
