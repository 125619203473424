import { useSessionCall } from "data/context/Sip";

interface CallActionUIProps {
  sessionId: string;
}

const CallActionUI = ({ sessionId }: CallActionUIProps) => {
  const {
    isHeld,
    isMuted,
    decline,
    hangup,
    hold,
    mute,
    answer,
    session,
    unhold,
    unmute,
    direction,
    timer,
  } = useSessionCall(sessionId);
  return <div>{sessionId}</div>;
};

export default CallActionUI;
