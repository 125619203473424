import { IMAGES_APP } from "assets";
import { memo } from "react";
import usePhone from "./usePhone";
import { styles } from "./styles";
import { useSessionCall, useSIPProvider } from "data/context/Sip";
import { CONNECT_STATUS } from "types/Sip";
import { SessionState } from "sip.js";
import CallActionUI from "./CallActionUI";
import { Link } from "react-router-dom";

const Phone = () => {
  const {
    activeTab,
    search,
    historyPhone,
    modalShowKeyboard,
    phoneNumber,
    modalShowCalling,
    handleClickCall,
    handleAddNumber,
    handleRemoveNumber,
    handleSetShowKeyBoard,
    handleSetPhone,
    handleSetActiveTab,
    handleSetShowModalCallOutGoing,
  } = usePhone();

  const { sessions } = useSIPProvider();

  return (
    <div style={styles.container}>
      {/* Header */}\ {/* Search Header */}
      <div style={styles.wrapperSearchHeader}>
        <div style={styles.wrapperIConSearch}>
          <img src={IMAGES_APP.search} alt="search" style={styles.iconSearch} />
        </div>
        <input
          type="text"
          placeholder="Find person or number"
          style={styles.inputSearchHeader}
          value={search}
          onChange={handleSetPhone}
        />
      </div>
      {/* Bottom Tab */}
      <div style={styles.wrapperBottomTab}>
        <div
          style={styles.wrapperIconBottomTab}
          onClick={() => handleSetActiveTab("recent")}
        >
          <img
            src={IMAGES_APP.time}
            alt="time"
            style={{
              ...styles.iconBottomTab,
              ...(activeTab === "recent" ? styles.iconBottomTabActive : {}),
            }}
          />
          <span style={styles.textSectionBottomTab}>Gần đây</span>
        </div>
        <div
          style={styles.wrapperIconBottomTab}
          onClick={() => handleSetActiveTab("contact")}
        >
          <img
            src={IMAGES_APP.people}
            alt="people"
            style={{
              ...styles.iconBottomTab,
              ...(activeTab === "contact" ? styles.iconBottomTabActive : {}),
            }}
          />
          <span style={styles.textSectionBottomTab}>Danh bạ</span>
        </div>
      </div>
      {/* Keyboard Button */}
      {/* <Link to="/keyboard-phone">
        <img
          src={IMAGES_APP.keyboardDialog}
          alt="keyboard"
          style={styles.iconKeyboardDialog}
        />
      </Link> */}
      <img
        onClick={() => handleSetShowKeyBoard(true)}
        src={IMAGES_APP.keyboardDialog}
        alt="keyboard"
        style={styles.iconKeyboardDialog}
      />
      {/* Modal */}
      {modalShowKeyboard && (
        <div style={styles.modal}>
          <div style={styles.modalHeader}>
            <img
              src={IMAGES_APP.arrowLeft}
              alt="back"
              style={styles.iconLeftBack}
            />
            <button
              style={styles.modalBackButton}
              onClick={() => {
                handleSetShowKeyBoard(false, true);
              }}
            >
              Back
            </button>
          </div>
          <div style={styles.modalBody}>
            {/* Hiển thị số */}
            <div style={styles.phoneDisplay}>
              <span />
              <span style={styles.phoneNumber}>{phoneNumber}</span>
              <img
                onClick={handleRemoveNumber}
                src={IMAGES_APP.deleteText}
                alt="back"
                style={styles.iconDeleteText}
              />
            </div>

            {/* Bàn phím số */}
            <div style={styles.keypad}>
              {["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"].map(
                (key) => (
                  <button
                    key={key}
                    style={styles.key}
                    onClick={() => handleAddNumber(key)}
                  >
                    {key}
                  </button>
                )
              )}
            </div>

            {/* Nút gọi */}
            <div>
              <img
                onClick={handleClickCall}
                src={IMAGES_APP.btnCall}
                alt="call"
                style={styles.iconBtnCall}
              />
            </div>
          </div>
        </div>
      )}
      {/* Modal Calling */}
      {modalShowCalling && (
        <div style={styles.modal}>
          <div>
            <img src={IMAGES_APP.avatar} alt="avatar" style={styles.avatar} />
          </div>
          <p>{phoneNumber}</p>
          <div style={styles.wrapperActionInCall}>
            <CallActionUI
              sessionId={Object.keys(sessions)[0]}
              handleSetShowModalCallOutGoing={handleSetShowModalCallOutGoing}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Phone);
