export const styles: { [key: string]: React.CSSProperties } = {
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
    width: "90%",
    marginBottom: "1rem",
    color: "#fff",
    fontSize: "1rem",
  },
  modalBackButton: {
    border: "none",
    borderRadius: "0.5rem",
    cursor: "pointer",
    backgroundColor: "#fff",
  },
  modalBody: {
    backgroundColor: "#fff",
    padding: "2rem",
    borderRadius: "1rem",
    textAlign: "center",
    width: "90%",
  },
  phoneDisplay: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  phoneNumber: {
    fontSize: "1.5rem",
    textAlign: "center",
  },
  removeButton: {
    backgroundColor: "#f44336",
    color: "#fff",
    border: "none",
    borderRadius: "0.5rem",
    padding: "0.5rem 1rem",
    cursor: "pointer",
  },
  keypad: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    width: "100%",
    maxWidth: "300px",
    margin: "4rem auto 0",
  },
  key: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "4rem",
    height: "4rem",
    borderRadius: "50%",
    border: "1px solid #ccc",
    backgroundColor: "#f9f9f9",
    fontSize: "1.5rem",
    cursor: "pointer",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.2s ease, transform 0.2s ease",
  },
  keyActive: {
    backgroundColor: "#e0f7fa",
    transform: "scale(0.95)",
  },
  iconLeftBack: {
    width: "0.8rem",
  },
  iconDeleteText: {
    width: "2rem",
    height: "auto",
  },
  iconBtnCall: {
    width: "3rem",
    height: "auto",
    marginTop: "2rem",
  },
  avatar: {
    width: "8rem",
    borderRadius: "50%",
    marginTop: "2rem",
  },
  iconCancelBtn: {
    width: "4rem",
  },
  wrapperActionInCall: {
    paddingBottom: "15rem",
    display: "flex",
    gap: "2rem",
  },
};
